import React from 'react'

import { graphql } from 'gatsby'
import { v4 as uuid } from 'uuid'

import { BannerPage } from '../components/BannerPage'
import { Seo } from '../components/Seo'

const Faq = ({ data }) => {
  const { questions } = data

  const title = 'Frequently asked questions (FAQ)'
  const description =
    'Find out the most questions asked about the podcast. I will add more questions / answers along the way but if something is missing feel free to contact me!'

  return (
    <>
      <Seo title={title} description={description} pathname="faq/" isLanding />

      <BannerPage title={title} description={description} />

      <div className="max-w-6xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
        <div className="mt-6">
          {questions?.nodes.map((question) => (
            <dl className="space-y-8 divide-y divide-gray-200" key={uuid()}>
              <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base font-medium text-gray-900 md:col-span-5">
                  {question.question}
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base text-gray-500">{question.response}</p>
                </dd>
              </div>
            </dl>
          ))}
        </div>
      </div>
    </>
  )
}

export default Faq

export const query = graphql`
  query FaqQuery {
    questions: allFaqYaml {
      nodes {
        question
        response
      }
    }
  }
`
